import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../lib/app-routes';
import { useSubject } from '../../../lib/hooks';
import { navMenuShow$ } from '../../../lib/state';
import SocialMedia from '../../SocialMedia/SocialMedia';
import SVGIcon from '../../SVGIcon/SVGIcon';
import './NavMenu.scss';
import NavDropDown from '../../NavDropDown/NavDropDown';

const NavMenu = (): JSX.Element => {
  const show = useSubject<boolean>(navMenuShow$);

  const resourcesOptions = [
    { label: 'Shop', value: 'http://www.jobsnob.shop/' },
    { label: 'Calendar', value: 'https://info.jobsnob.net/resources' },
    { label: 'Training', value: 'https://medspanetwork.com/masterclass/february2025?affiliate=jobsnob#packages' }
  ];

  return (
    <div className={`header-menu-wrap${show ? ' show' : ''}`}>
      <button className='close-btn' onClick={() => navMenuShow$.next(!show)}>
        <SVGIcon id='close' color='hover-black' />
      </button>

      <ul>
        <li><Link to={ROUTES.jobboard.path} aria-current="page">Job Board</Link></li>
        <li><Link to={ROUTES.purchase.path} aria-current="page">Employers</Link></li>
        <li><a href="https://info.jobsnob.net/acquisitions" target="_self">Acquisitions</a></li>
        {/* <li><a href="https://info.jobsnob.net/aesthetic-data" target="_self">Aesthetic Data</a></li> */}
        <li className='dropdown'>
          <NavDropDown
            attributes={{ id: 'resources' }}
            options={resourcesOptions.map(option => ({ label: option.label, value: option.value }))}
            label='Resources'
            className='nav-dropdown'
            onSelected={(value: string) => window.location.href = value}
          />
        </li>
        <li><a href="https://info.jobsnob.net/blog" target="_self">Blog</a></li>
        <li><a href="https://info.jobsnob.net/about-us" target="_self">About Us</a></li>
      </ul>

      <SocialMedia fbColor='black' instaColor='hover-black' inColor='black' size={24} />
    </div>
  )
};

export default NavMenu;
